@use "./../variables" as var;

.head-container {
  width: 90vw;
  height: 30vh;
  margin: 0.5em;
  background-color: var.$quadrary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @include var.borders;

  h2 {
    color: white;
    margin: 0.7em;
    font-size: 1.3rem;
    @include var.tablet {
      font-size: 2rem;
    }
    @include var.desktop {
      font-size: 3rem;
    }
  }

  select {
    padding: 0.4em;
    margin-left: 10rem;
    font-size: 0.4rem;
    border-radius: 5px;
    border: solid 2px black;
    @include var.tablet {
      font-size: 1rem;
      margin-left: 0;
    }
    @include var.desktop {
      font-size: 1.2rem;
    }
  }
}
