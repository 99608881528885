@use "./colors" as var;

@mixin buttons($color, $secondColor) {
  background: $color;
  color: $secondColor;
  border: none;
  padding: 0;
  margin: 0.4em;
  font-size: 1em;
  overflow: hidden;
  width: 5em;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: $secondColor;
    color: $color;
  }
}

@mixin borders {
  border: 5px black solid;
  border-radius: 15px;
  box-shadow: 10px 10px;
}

@mixin tablet {
  @media screen and (min-width: 900px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1200px) {
    @content;
  }
}
