@use "./../variables/" as var;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "press start 2p", sans-serif, Arial, Helvetica, sans-serif, 'Times New Roman', Times, serif;
}

.main-container{
    min-height: 100vh;
    background-color: var.$primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}