* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "press start 2p", sans-serif, Arial, Helvetica, Times New Roman, Times, serif;
}

.main-container {
  min-height: 100vh;
  background-color: #3a86ff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#input-text {
  width: 25em;
  background: #fff;
  border: 3px solid #000;
  border-radius: 5px;
  margin: .5em;
  padding: .3em;
  font-size: .6rem;
}

@media screen and (min-width: 900px) {
  #input-text {
    font-size: 1.1rem;
  }
}

#add-todo {
  color: #fff;
  width: 5em;
  height: 35px;
  cursor: pointer;
  background: #ff5e00;
  border: none;
  border-radius: 5px;
  margin: .4em;
  padding: 0;
  font-size: .6rem;
  overflow: hidden;
}

#add-todo:hover {
  color: #ff5e00;
  background-color: #fff;
}

@media screen and (min-width: 900px) {
  #add-todo {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1200px) {
  #add-todo {
    font-size: 1.5rem;
  }
}

.doneBtn {
  color: #8338ec;
  width: 5em;
  height: 35px;
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 5px;
  margin: .4em;
  padding: 0;
  font-size: 1em;
  overflow: hidden;
}

.doneBtn:hover {
  color: #fff;
  background-color: #8338ec;
}

.throwBtn {
  color: #ff5e00;
  width: 5em;
  height: 35px;
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 5px;
  margin: .4em;
  padding: 0;
  font-size: 1em;
  overflow: hidden;
}

.throwBtn:hover {
  color: #fff;
  background-color: #ff5e00;
}

.head-container {
  width: 90vw;
  height: 30vh;
  background-color: #ff5e00;
  border: 5px solid #000;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: .5em;
  display: flex;
  overflow: hidden;
  box-shadow: 10px 10px;
}

.head-container h2 {
  color: #fff;
  margin: .7em;
  font-size: 1.3rem;
}

@media screen and (min-width: 900px) {
  .head-container h2 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .head-container h2 {
    font-size: 3rem;
  }
}

.head-container select {
  border: 2px solid #000;
  border-radius: 5px;
  margin-left: 10rem;
  padding: .4em;
  font-size: .4rem;
}

@media screen and (min-width: 900px) {
  .head-container select {
    margin-left: 0;
    font-size: 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .head-container select {
    font-size: 1.2rem;
  }
}

.todo-wrapper {
  width: 90vw;
  min-height: 60vh;
  background-color: #ffbe0b;
  border: 5px solid #000;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  margin: .5em;
  display: flex;
  box-shadow: 10px 10px;
}

.todo {
  width: 87vw;
  align-self: flex-start;
  font-size: .6rem;
  list-style: none;
}

@media screen and (min-width: 900px) {
  .todo {
    width: 65vw;
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1200px) {
  .todo {
    width: 50vw;
    font-size: 1.5rem;
  }
}

.todo--container {
  background: #fff;
  border: 3px solid #000;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  margin: .8em;
  font-weight: bold;
  display: flex;
  box-shadow: 2px 2px;
}

.todo--container__dot {
  width: 10em;
  flex: 1;
  margin-left: 1em;
}

.doneDone {
  opacity: .5;
}

/*# sourceMappingURL=index.83822a4a.css.map */
